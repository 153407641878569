import * as styles from './examples.module.scss';

import Callout from '../components/v2/callout';
import { ExamplesIndustries } from '../content/examples';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { Link } from 'gatsby';
import React from 'react';
import SEO from '../components/seo';
import Section from '../components/section';

const ExamplesPage = () => {
  return (
    <>
      <SEO
        title="Quiz Examples"
        description="Take live quizzes and experience how interactive quizzes function. Each of these quizzes is from an Interact customer - they are using their quizzes for lead generation, product recommendations, and social media promotions."
      />
      <Header btnTheme="primary" />
      <main className={styles.examples}>
        <svg width="0" height="0">
          <defs>
            <linearGradient id="primaryGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="50%" stopColor="rgb(115, 113, 252)" />
              <stop offset="100%" stopColor="rgb(154, 65, 254)" />
            </linearGradient>
          </defs>
        </svg>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Quiz Examples</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h2 className="text-body color-muted-foreground">
                Take live quizzes and experience how interactive quizzes function. Each of these
                quizzes is from an Interact customer - they are using their quizzes for lead
                generation, product recommendations, and social media promotions.
              </h2>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row">
            {ExamplesIndustries.map((category, index) => {
              return (
                <div className="col-md-6 col-lg-4" key={index} style={{ marginBottom: `1.5rem` }}>
                  <Link to={`/examples/${category.slug}`} style={{ textDecoration: 'none' }}>
                    <div className={styles.card}>
                      <div className={styles.icon}>
                        <FontAwesomeIcon icon={category.icon} />
                      </div>
                      <div className={styles.content}>
                        <p className="heading-sm color-black">
                          <strong>{category.label}</strong>
                        </p>
                        <p
                          className="text-body color-muted-foreground"
                          style={{ fontSize: '14px' }}>
                          {category.description}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default ExamplesPage;
